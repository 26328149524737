.box {
  background-color: #ffffff;
  border: none;
  border-radius: 10%;
  box-shadow: 0px 0px 8px #888888;
  width: 5rem;
  height: 5rem;
  text-align: center;
  font-size: 5em;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 5rem;
  margin: 0.5rem;
}

/* iphone 13 */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) { 

  .box {
    padding: 0
  }
}

/* iphone 13 mini */
@media only screen 
  and (min-device-width: 360px) 
  and (max-device-width: 780px)
  and (-webkit-min-device-pixel-ratio: 3) { 

  .box {
    padding: 0
  }
}

.x {
  color: rgb(255, 70, 37);
}

.o {
  color: rgb(44, 135, 255);
}

.box:hover {
  box-shadow: 0px 0px 15px #888888;
}

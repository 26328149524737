.toggle {
    text-align: center;
  }
  
  .toggle .switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .toggle .switch-container span {
    margin: 0 10px;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
  }
  
  
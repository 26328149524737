.reset-btn {
  border: none;
  border-radius: 0.5rem;
  background-color: rgb(0, 110, 255);
  color: white;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  margin: 2rem auto;
  display: block;
}

.reset-btn:hover {
  background-color: rgb(0, 119, 255);
}
